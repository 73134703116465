import { Lifecycle } from '@firebolt-js/sdk'
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { useCallback, useEffect, useRef, useState } from 'react'

type TOnVisibilityChange = () => void | Promise<void>

export const useAppVisibility = (onForeground?: TOnVisibilityChange, onBackground?: TOnVisibilityChange) => {
  const [isForeground, setIsForeground] = useState(true)

  // COMCAST
  let comcastForegroundListenerId = useRef<number | undefined>(-1)
  let comcastBackgroundListenerId = useRef<number | undefined>(-1)
  const comcastListen = useCallback(async () => {
    // on foreground
    comcastForegroundListenerId.current = await Lifecycle.listen('foreground', (e) => {
      setIsForeground(true)
    })

    // on background
    comcastBackgroundListenerId.current = await Lifecycle.listen('background', (e) => {
      setIsForeground(false)
    })
  }, [])

  // Subscribe to events platform-specific foreground/background events
  useEffect(() => {
    const { platform } = getWindowDeviceInfo()

    if (platform === 'hbbtv') {
      comcastListen()
      return () => {
        if (comcastForegroundListenerId.current) {
          Lifecycle.clear(comcastForegroundListenerId.current)
        }
        if (comcastBackgroundListenerId.current) {
          Lifecycle.clear(comcastBackgroundListenerId.current)
        }
      }
    }

    const handleVisibilityChange = () => {
      setIsForeground(document.visibilityState === 'visible')
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [comcastListen])

  const onBackgroundRef = useRef(onBackground)
  const onForegroundRef = useRef(onForeground)
  onBackgroundRef.current = onBackground
  onForegroundRef.current = onForeground

  useEffect(() => {
    if (isForeground) {
      onForegroundRef.current?.()
    } else {
      onBackgroundRef.current?.()
    }
  }, [isForeground])

  return {
    isForeground,
    isBackground: !isForeground,
  }
}
